// Generated by Framer (d351ec1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mQutnNzOa", "KVNaQbTdD", "n0GSjFSw7"];

const serializationHash = "framer-V4PFF"

const variantClassNames = {KVNaQbTdD: "framer-v-1tbwz1e", mQutnNzOa: "framer-v-8ljivt", n0GSjFSw7: "framer-v-rxyr47"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Desktop - 40px": "mQutnNzOa", "Phone - 20px": "n0GSjFSw7", "Tablet - 32px": "KVNaQbTdD"}

const getProps = ({height, id, visible, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mQutnNzOa", wb4qzJX7V: visible ?? props.wb4qzJX7V ?? true} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;visible?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wb4qzJX7V, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mQutnNzOa", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}>{wb4qzJX7V && (<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-8ljivt", className, classNames)} data-framer-name={"Desktop - 40px"} layoutDependency={layoutDependency} layoutId={"mQutnNzOa"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({KVNaQbTdD: {"data-framer-name": "Tablet - 32px"}, n0GSjFSw7: {"data-framer-name": "Phone - 20px"}}, baseVariant, gestureVariant)}/></Transition>)}</Variants>
</LayoutGroup>)

});

const css = [".framer-V4PFF[data-border=\"true\"]::after, .framer-V4PFF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-V4PFF.framer-j3tyo8, .framer-V4PFF .framer-j3tyo8 { display: block; }", ".framer-V4PFF.framer-8ljivt { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-V4PFF.framer-8ljivt { gap: 0px; } .framer-V4PFF.framer-8ljivt > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-V4PFF.framer-8ljivt > :first-child { margin-top: 0px; } .framer-V4PFF.framer-8ljivt > :last-child { margin-bottom: 0px; } }", ".framer-V4PFF.framer-v-1tbwz1e.framer-8ljivt { height: 32px; width: 32px; }", ".framer-V4PFF.framer-v-rxyr47.framer-8ljivt { height: 20px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KVNaQbTdD":{"layout":["fixed","fixed"]},"n0GSjFSw7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wb4qzJX7V":"visible"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framery46y6NrsT: React.ComponentType<Props> = withCSS(Component, css, "framer-V4PFF") as typeof Component;
export default Framery46y6NrsT;

Framery46y6NrsT.displayName = "H Block";

Framery46y6NrsT.defaultProps = {height: 40, width: 40};

addPropertyControls(Framery46y6NrsT, {variant: {options: ["mQutnNzOa", "KVNaQbTdD", "n0GSjFSw7"], optionTitles: ["Desktop - 40px", "Tablet - 32px", "Phone - 20px"], title: "Variant", type: ControlType.Enum}, wb4qzJX7V: {defaultValue: true, title: "Visible", type: ControlType.Boolean}} as any)

addFonts(Framery46y6NrsT, [])